import { Box, Container, Flex, Text, useColorMode } from "@chakra-ui/react";
import React, { useEffect } from "react";
import {useStaticQuery,graphql } from "gatsby";
import { Helmet } from "react-helmet";
import ImgTextModule from "../components/ImgTextModule";
import NewsListItem from "../components/NewsListItem";
import NewsListItemHref from "../components/NewsListItemHref";
import QuadKachelnInvRelations from "../components/QuadKachelnInvRelations";
import FadeInAnimation from "../DefinitionComponents/Animation-Components/FadeInAnimation";

const News = () => {
  const { setColorMode } = useColorMode();

  useEffect(() => {
    setColorMode("light");
  });


  const data = useStaticQuery(graphql`
  query getNews {
    allWpNews(sort: {fields: date, order: DESC}) {
      nodes {
        dateGmt
        date(formatString: "DD.MM.yyyy", locale: "DE")
        newsDatum
        newsTitle
        newsUrl
        pDFOderURL
        pdfNews {
          filename
          mediaType
          localFile {
            absolutePath
            relativePath
          }
        }
      }
    }
 }
   `);
   const DIDDS = data.allWpNews.nodes;


  return (
    <>
      <Helmet>
        <title>tokentus | Newsroom</title>
        <meta
          name="description"
          content="The latest news featuring tokentus investment AG. "
        />
        <link rel="canonical" href="https:/tokentus.com/news" />
      </Helmet>

      <ImgTextModule
        src={"news.mp4"}
        delay={0.3}
        duration={0.5}
        threshold={0.3}
        initialY={10}
        initialX={10}
        video={true}
        h="55vh"
        mediaOpacity={0.8}
        bgColor="brand.black"
      >
        <Container variant="layoutContainer" mt={8}>
          <Box p={8}>
            <FadeInAnimation
              threshold={0.4}
              delay={0.4}
              duration={0.7}
              initialX={0}
              initialY={-10}
            >
              <Text color="brand.medgray" fontWeight="normal" fontSize="6xl">
                Newsroom.
              </Text>
            </FadeInAnimation>
            <FadeInAnimation
              threshold={0.4}
              delay={1}
              duration={0.7}
              initialX={0}
              initialY={10}
            >
              <Text
                color="brand.white"
                mb={4}
                maxW={{ base: "100%", lg: "50%" }}
                fontSize="2xl"
              >
                News from tokentus.
              </Text>
            </FadeInAnimation>
          </Box>
        </Container>
      </ImgTextModule>
      {/* NEWS */}

      <Container
        variant="layoutContainer"
        px={{ base: 0, lg: 10 }}
        py={{ base: 0, lg: 10 }}
      >
        <Flex
          flexWrap="wrap"
          flexDirection="column"
          justifyContent={"space-between"}
          
        >
  {DIDDS.map((News)=>(  
      <>

        {News.pDFOderURL ? (  
          <>
             
               <NewsListItem
               datum={News.newsDatum}
               textinhalt={News.newsTitle}
               pdflink={News.pdfNews.localFile.relativePath}
                       /> 
           </>
             
        )
            :
 (      
          <NewsListItemHref
          datum={News.newsDatum}
          textinhalt={News.newsTitle}
          actionlink={News.newsUrl  }
        />
 )
      }
         
</>
 ))}
        </Flex>
      </Container>
      <QuadKachelnInvRelations />
    </>
  );
};

export default News;
