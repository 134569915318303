import {
  Box,
  Button, Flex, Heading, HStack,
  VStack
} from "@chakra-ui/react";
import { Link } from "gatsby";import moment from "moment";
import React from "react";
import ImgMaskReveal from "../DefinitionComponents/Animation-Components/ImgMaskReveal";


function NewsListItemHref({ datum, textinhalt, actionlink }) {
  const date = moment(datum).format('DD.MM.YYYY');
  console.log(date);
  return (
    <Box w={{ base: "100%", lg: "100%" }} mt={1}>
      <Flex wrap="wrap" flexDirection={"row"}>
        <HStack
          visibility={{ base: "hidden", lg: "visible" }} display={{base:"none", md:'flex'}}
          w="20%" minH="220px"
          p={0}
          spacing={0}
          bg={"brand.blue"}
        >
          <ImgMaskReveal
            imgsrc={"newsthumb2.png"}
         
            initScale={1.5}
            delay={0}
            duration={2}
            threshold={0.4}
            triggerOnce={true}
          />
        </HStack>
        <VStack
          w={{ base: "100%", lg: "80%" }}
          px={10}
          py={10}
          alignItems={"flex-start"}
          bg={"brand.lightgray"}
        >
          <Heading
            fontSize="md"
            fontWeight="normal"
            lineHeight={"none"}
            color="brand.blue"
          >
            {date}
          </Heading>
          <Heading
            w={{ base: "100%", lg: "90%" }}
            fontSize="xl"
            fontWeight="normal"
            lineHeight={"shorter"}
            color="brand.meddarkgray"
          >
            {textinhalt}
          </Heading>
          {actionlink  &&  (
          <Link target="_blank" href={actionlink}>
            <Button
              color="white"
              size={"lg"}
              fontWeight={"normal"}
              px={6}
              backgroundColor="brand.meddarkgray"
            >
              ext. Link
            </Button>
          </Link>
            )
        
          }
        </VStack>
      </Flex>
    </Box>
  );
}

export default NewsListItemHref;
